.alert-message-div {
    width: 350px;
    margin: 3px auto;
    position: fixed;
    top: 200px;
    right: 455px;
    justify-content: center;
    z-index: 122;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .alert-styles {
    padding: 4px 4px 0px 4px !important;
  }
  .alert-success-heading-txt {
    color:green
  }
  .alert-error-heading-txt {
    color:orange;
  }
  p-0 {
    padding: 0px;
  }
  .m-0 {
    margin: 0px;
  }
  .m-b{
      margin-bottom: 0px;
  }
  .close-icon {
    cursor: pointer;
  }
  .close-icon:hover{
    font-size: 19px;
  }
  .alert-img-width {
    width: 13%;
    height: 14%;
    margin-right: 8px;

  }
  .alert-img1-width {
    width: 14%;
    margin-right: 5px;
    height: 11%;
  }
  