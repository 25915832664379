@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  font-family: "Poppins";
}
body {
  background: white !important;
}
.sub-content {
  padding: 0px;
  /* min-height: 100vh; */
  margin: 20px auto;
  width: 52%;
}
.form-main-div {
  margin-top: 20px;
}
.font-20 {
  font-size: 20px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.m-top {
  margin-top: 12px;
}
.form-group {
  margin-bottom: 10px !important;
}
.form-card {
  box-shadow: 0 0 8px 0 hsla(0, 0%, 62.7%, 0.5);
  padding: 20px 70px;
  border-radius: 7px;
}

.font-styling-1 {
  font-size: 14px;
  color: #0093ff;
}

/********** select css **********/
.css-1wa3eu0-placeholder {
  font-size: 14px !important;
}
.css-26l3qy-menu {
  font-size: 14px !important;
}
.css-1uccc91-singleValue {
  font-size: 14px !important;
}
/***********form css ************/
.form-control {
  font-size: 14px !important;
}

textarea {
  width: 100%;
  font-size: 13px !important;
  height: 78px !important;
  padding-left: 7px;
  padding-top: 4px;
}
.gap-Class{
  padding: 19px 10px;
}
/* input#exampleInputEmail1 {
  padding: 19px 10px;
} */
/********** */
#imgwidth {
  width: 78%;
  margin-left: 75px;
}

/********btn css ************/
button.btn.btn-animation.btn-primary {
  font-size: 14px;
  background: #00a1ff;
  border-color: #00a1ff;
}
.btn-animation span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn-animation span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  font-size: 22px;
  top: -7px;
  right: -20px;
  transition: 0.5s;
}

.btn-animation:hover span {
  padding-right: 30px;
}

.btn-animation:hover span:after {
  opacity: 1;
  right: 0;
}

/******************************/

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
}
.dropdown-submenu {
  position:relative;
}
.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top:-6px;
}

/* rotate caret on hover */
.dropdown-menu > li > span:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
} 
/*****************drop down css**************/
.navbar {
    padding: 1px !important;
    border-radius: 4px;
    background: white !important;
    border: 1px solid #dbd5d5;
}
.navbar-nav{
  width:100% !important;
}
li.nav-item.dropdown{
  width:80% !important;
}
.navbar-nav .nav-link{
  font-size: 14px !important;
  padding: 5px;
  color: gray !important;
}
.dropdown-item{
  font-size: 14px;
  color: #4b4b4b !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #0cb8df !important;
  color: white !important;
}
a#navbarDropdownMenuLink::after {
  display: none;
}
.item-style {
  cursor: pointer;
}